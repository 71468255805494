import { evaluateExpression } from './evaluateExpression';
import { recursiveUpdate } from './recursiveUpdate';

export const getComputedData = <T>(
  data: Record<string, unknown> | string | unknown[],
  context: Record<string, unknown>,
  shouldEvaluateResult = true,
) => {
  return recursiveUpdate(data, (value) => {
    return evaluateExpression({
      context,
      expression: value as string,
      shouldEvaluateResult,
    });
  }) as T;
};
