import { isDynamicValue, stringToJS } from './dynamicBindings';
import { defaultUpdater } from './recursiveUpdate';
import { indirectEvalExpression } from './indirectEval';

export function evaluateResult<T>({
  context,
  resolvedStringToEvaluate,
  shouldEvaluateResult,
}: {
  context: object;
  resolvedStringToEvaluate: string;
  shouldEvaluateResult: boolean;
}): T | undefined {
  try {
    const result = indirectEvalExpression(resolvedStringToEvaluate, context) as string;

    // if result value is also a dynamic value, then evaluate it recursively
    if (shouldEvaluateResult && result && isDynamicValue(result)) {
      return evaluateExpression({
        context,
        expression: result,
        shouldEvaluateResult,
      });
    }

    return result as T;
  } catch (e) {
    // console.error(`Error in evaluating expression ${resolvedStringToEvaluate} with context`, context, e);
  }
}

function evaluateExpression<T>({
  context,
  expression,
  shouldEvaluateResult = true,
}: {
  expression: string;
  context: object;
  // this flag is used to resolve the result of the expression if it is a dynamic value
  // it keeps on evaluating the expression until it is not a dynamic value anymore
  shouldEvaluateResult?: boolean;
}): T | undefined {
  if (!isDynamicValue(expression)) return expression as T;

  const toBeResolvedJS = stringToJS(expression);
  const resolvedStringToEvaluate = defaultUpdater(context, toBeResolvedJS) as string | undefined;

  if (resolvedStringToEvaluate === undefined) return undefined;

  return evaluateResult<T>({
    context,
    resolvedStringToEvaluate,
    shouldEvaluateResult,
  });
}

export { evaluateExpression };
