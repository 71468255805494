import type { Op } from './Op';

export class Filter {
  #field: string;
  #op: Op | null;
  #values?: unknown[];
  #options: Record<string, unknown>;
  #metadata: Record<string, string>;

  withField(field: string): this {
    this.#field = field;
    return this;
  }

  getField(): string {
    return this.#field;
  }

  setField(field: string): void {
    this.#field = field;
  }

  withOp(op: Op): this {
    this.#op = op;
    return this;
  }

  getOp(): Op | null {
    return this.#op;
  }

  setOp(op: Op): void {
    this.#op = op;
  }

  getValues(): unknown[] | null {
    if (!this.#values?.length) {
      return null;
    }
    return this.#values;
  }

  getMetadata(): Record<string, string> {
    return this.#metadata;
  }

  setMetadata(metadata: Record<string, string>): void {
    this.#metadata = metadata;
  }

  firstValue(): unknown | null {
    if (!this.#values?.length) {
      return null;
    }
    return this.#values[0];
  }

  secondValue(): unknown | null {
    if (!this.#values?.length || this.#values.length <= 1) {
      return null;
    }
    return this.#values[1];
  }

  firstValueAsType<T>(): T | null {
    if (!this.#values?.length) {
      return null;
    }
    return this.#values[0] as T;
  }

  setValues(values: unknown[]): void {
    this.#values = values;
  }

  withValues(values: unknown[]): this {
    this.#values = values;
    return this;
  }

  withOptions(options: Record<string, unknown>): this {
    this.#options = options;
    return this;
  }

  addValue(value: unknown): this {
    if (value === null) {
      return this;
    }
    if (!this.#values) {
      this.#values = [];
    }
    this.#values.push(value);
    return this;
  }

  getOptions(): Record<string, unknown> {
    return this.#options;
  }

  setOptions(options: Record<string, unknown>): void {
    this.#options = options;
  }

  addOption(key: string, value: unknown): this {
    if (value === null) {
      return this;
    }
    if (!this.#options.length) {
      this.#options = {};
    }
    this.#options[key] = value;
    return this;
  }

  toFilter(): Filter | null {
    if (this.#op && this.#values) {
      const newFilter = new Filter()
        .withField(this.#field)
        .withOp(this.#op)
        .withValues(this.#values);
      return newFilter;
    }
    return null;
  }

  toString(): string {
    return `${this.#field} ${this.#op?.toString()} ${this.#values?.toString()}`;
  }
}
