import { isEmpty } from 'lodash';
import type { FilterContext } from '../api/FilterContext';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import { CompoundOpFilterBuilder } from './CompoundOpFilterBuilder';

export class OrFilterBuilder extends CompoundOpFilterBuilder {
  protected _build<RecordType>(
    filterContext: FilterContext<RecordType>,
    evaluators: FilterEvaluator<RecordType>[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(evaluators)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    return new OrFilterEvaluator(evaluators);
  }
}

class OrFilterEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private evaluators: FilterEvaluator<RecordType>[];

  constructor(evaluators: FilterEvaluator<RecordType>[]) {
    this.evaluators = evaluators;
  }

  evaluate(record: RecordType): boolean {
    for (const evaluator of this.evaluators) {
      if (evaluator.evaluate(record)) {
        return true;
      }
    }
    return false;
  }
}
