import { getComputedData } from '../../utils/getComputedData';

export class ExecutionInstance {
  readonly #_context: Record<string, unknown>;

  constructor(instance: Record<string, unknown>) {
    this.#_context = instance;
  }

  resolve(
    value: string | Record<string, unknown>,
  ): string | object | number | boolean | null | undefined {
    if (typeof value === 'string') {
      const tempobject = { value };
      const resolved = getComputedData<typeof tempobject>(tempobject, this.#_context);
      return resolved.value as string | undefined;
    }

    return getComputedData(value, this.#_context);
  }

  get context(): Record<string, unknown> {
    return this.#_context;
  }
}
