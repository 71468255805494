import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Filter } from '../api/Filter';
import type { Value } from '../api/Value';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class RegexFilterBuilder extends LeafFilterBuilder {
  caseInsensitive: boolean;

  constructor(caseInsensitive = false) {
    super();
    this.caseInsensitive = caseInsensitive;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const regexPattern = this.toComparableValues(field, values)[0];
    return new RegexEvaluator(field, regexPattern, this.caseInsensitive);
  }
}

export class RegexEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private regexPattern: Value;
  private flags: string;

  constructor(field: Field<RecordType>, regexPattern: Value, caseInsensitive: boolean) {
    this.field = field;
    this.flags = caseInsensitive ? 'i' : '';
    this.regexPattern = regexPattern;
  }

  public evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (!iterable) {
      return false;
    }
    for (const v of iterable) {
      if (this.evaluateValue(v)) {
        return true;
      }
    }
    return false;
  }

  private evaluateValue(v: Value): boolean {
    return v.matchedRegex(this.regexPattern, this.flags);
  }
}
