import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Filter } from '../api/Filter';
import { InFilterBuilder } from './InFilterBuilder';
import { EqualsFilterEvaluator } from './EqualsFilterBuilder';

export class NotEqualsFilterBuilder extends InFilterBuilder {
  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }

    const comparableValues = this.toComparableValues(field, values);
    return new NotEqualsFilterEvaluator(field, comparableValues);
  }
}

export class NotEqualsFilterEvaluator<RecordType> extends EqualsFilterEvaluator<RecordType> {
  public evaluate(record: RecordType): boolean {
    return !super.evaluate(record);
  }
}
