import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Value } from '../api/Value';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class InFilterBuilder extends LeafFilterBuilder {
  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const comparableValues = this.toComparableValues(field, values);
    return new InFilterEvaluator(field, comparableValues);
  }
}

export class InFilterEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private readonly values: Value[];

  constructor(field: Field<RecordType>, values: Value[]) {
    this.field = field;
    this.values = values;
  }

  evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (iterable === null) {
      return false;
    }

    for (const v of iterable) {
      if (v.in(this.values[0])) {
        return true;
      }
    }
    return false;
  }
}
