import { useCallback } from 'react';
import type { ConditionEvaluationObject } from '@unifyapps/defs/types/conditionEvaluationObject';
import { ExecutionInstance } from '../../filter/api/ExecutionInstance';
import { UIFilter } from '../../filter/api/UIFilter';
import { ConditionEvaluatorImpl } from '../../filter/ConditionEvaluatorHelperImpl';
import { recursiveUpdateWithSelector } from '../../utils/recursiveUpdate';

export const KEYS_TO_CHECK = [
  'hidden',
  'ui:disabled',
  'disabled',
  'runCondition',
  'conditions',
  'loading',
  'error',
];

export function useComputeConditionEvaluationGetter() {
  return useCallback(
    <T>(props: Record<string, unknown>, context: Record<string, unknown>) =>
      recursiveUpdateWithSelector({
        obj: props,
        updater: (key, value) => {
          const conditionEvaluationObj = value as ConditionEvaluationObject | undefined;

          if (!conditionEvaluationObj) {
            return value as T;
          }

          if (conditionEvaluationObj.type === 'boolean') {
            return conditionEvaluationObj.payload;
          }

          const instance = new ExecutionInstance(context);

          try {
            const uiFilter = UIFilter.createUIFilter(conditionEvaluationObj.payload);
            const filter = uiFilter.toFilter();
            const conditionEvaluator = new ConditionEvaluatorImpl();
            return conditionEvaluator.evaluate(filter, instance);
          } catch (e) {
            console.error(
              `Error evaluating condition for key: ${key}`,
              e,
              conditionEvaluationObj.payload,
            );
          }
        },
        // valueSelector is a function that takes a key and a value and returns a boolean if the value should be updated
        valueSelector: (key, value) => {
          // Cast the value to an object with optional properties 'type' and 'payload'
          const _value = value as { type?: string; payload?: string } | undefined;

          // If the value is undefined, return false
          if (!_value) return false;

          // Destructure the 'type' and 'payload' properties from the value
          const { type, payload } = _value;

          // Return true if the following conditions are met:
          // - The key is included in the KEYS_TO_CHECK array
          // - The type is 'filter' and the payload is an object
          // - The type is 'boolean' and the payload is a boolean
          // Otherwise, return false
          return Boolean(
            KEYS_TO_CHECK.includes(key) &&
              ((type === 'filter' && typeof payload === 'object') ||
                (type === 'boolean' && typeof payload === 'boolean')),
          );
        },
      }) as T,
    [],
  );
}
