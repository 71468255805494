export enum DataType {
  ANY = 'ANY',
  OBJECT = 'OBJECT',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
}

export class Op {
  static readonly #DATA_TYPE_OP_MAP = new Map<DataType, Set<Op>>();

  constructor(
    readonly compound = false,
    readonly expectedDataType: DataType = DataType.ANY,
  ) {
    let set = Op.#DATA_TYPE_OP_MAP.get(expectedDataType);
    if (!set) {
      set = new Set();
      Op.#DATA_TYPE_OP_MAP.set(expectedDataType, set);
    }
    set.add(this);
  }

  static readonly AND = new Op(true);
  static readonly OR = new Op(true);
  static readonly EQUAL = new Op();
  static readonly NOT_EQUAL = new Op();
  static readonly IN = new Op(false, DataType.OBJECT);
  static readonly NOT_IN = new Op(false, DataType.OBJECT);
  static readonly LT = new Op(false, DataType.NUMBER);
  static readonly LTE = new Op(false, DataType.NUMBER);
  static readonly GT = new Op(false, DataType.NUMBER);
  static readonly GTE = new Op(false, DataType.NUMBER);
  static readonly REGEX = new Op(false, DataType.STRING);
  static readonly NOT_REGEX = new Op(false, DataType.STRING);
  static readonly CONTAINS = new Op(false, DataType.STRING);
  static readonly NOT_CONTAINS = new Op(false, DataType.STRING);
  static readonly IREGEX = new Op(false, DataType.STRING);
  static readonly NOT_IREGEX = new Op(false, DataType.STRING);
  static readonly EXISTS = new Op();
  static readonly MISSING = new Op();

  // date type
  static readonly DATE_IS_BEFORE = new Op(false, DataType.STRING);
  static readonly DATE_IS_AFTER = new Op(false, DataType.STRING);
  static readonly DATE_IS_ON_OR_BEFORE = new Op(false, DataType.STRING);
  static readonly DATE_IS_ON_OR_AFTER = new Op(false, DataType.STRING);

  //time type
  static readonly TIME_IS_EQUAL_TO_OR_AFTER = new Op(false, DataType.STRING);
  static readonly TIME_IS_EQUAL_TO_OR_BEFORE = new Op(false, DataType.STRING);
  static readonly TIME_IS_BEFORE = new Op(false, DataType.STRING);
  static readonly TIME_IS_AFTER = new Op(false, DataType.STRING);
  static readonly TIME_IS_EQUAL_TO = new Op(false, DataType.STRING);

  //  static readonly NOT = new Op(true);
  //  static readonly BETWEEN = new Op();
  //  static readonly ICONTAINS = new Op(false, DataType.STRING);
  //  static readonly NOT_ICONTAINS = new Op(false, DataType.STRING);
  //  static readonly STARTS_WITH = new Op(false, DataType.STRING);
  //  static readonly ENDS_WITH = new Op(false, DataType.STRING);
  //  static readonly CONTEXTUAL = new Op(true, DataType.OBJECT);
  static readonly MIN_LENGTH = new Op(false, DataType.STRING);
  static readonly MAX_LENGTH = new Op(false, DataType.STRING);

  isCompound(): boolean {
    return this.compound;
  }

  getExpectedDataType(): DataType {
    return this.expectedDataType;
  }

  toString(): string {
    return `${this.constructor.name}(${this.expectedDataType})`;
  }
}
