import { isEmpty } from 'lodash';
import type { FilterContext } from '../api/FilterContext';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchAllEvaluator } from '../api/MatchAllEvaluator';
import { CompoundOpFilterBuilder } from './CompoundOpFilterBuilder';

export class AndFilterBuilder extends CompoundOpFilterBuilder {
  protected _build<RecordType>(
    filterContext: FilterContext<RecordType>,
    evaluators: FilterEvaluator<RecordType>[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(evaluators)) {
      return MatchAllEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    return new AndFilterEvaluator(evaluators);
  }
}

class AndFilterEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private evaluators: FilterEvaluator<RecordType>[];

  constructor(evaluators: FilterEvaluator<RecordType>[]) {
    this.evaluators = evaluators;
  }

  evaluate(record: RecordType): boolean {
    for (const evaluator of this.evaluators) {
      if (!evaluator.evaluate(record)) {
        return false;
      }
    }
    return true;
  }
}
