import { get } from 'lodash';
import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchAllEvaluator } from '../api/MatchAllEvaluator';
import { LeafFilterBuilder } from './LeafFilterBuilder';
import { ContainsEvaluator } from './ContainsFilterBuilder';

export class NotContainsFilterBuilder extends LeafFilterBuilder {
  caseInsensitive: boolean;

  constructor(caseInsensitive = false) {
    super();
    this.caseInsensitive = caseInsensitive;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (values.length === 0) {
      return MatchAllEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const caseInsensitiveFromOption = get(filter.getOptions(), 'case_insensitive');
    const caseInsensitive = Boolean(caseInsensitiveFromOption || this.caseInsensitive);
    const comparableValues = this.toComparableValues(field, values);

    return new NotContainsEvaluator(field, comparableValues[0], caseInsensitive);
  }
}

class NotContainsEvaluator<RecordType> extends ContainsEvaluator<RecordType> {
  public evaluate(record: RecordType): boolean {
    return !super.evaluate(record);
  }
}
