import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Filter } from '../api/Filter';
import { LeafFilterBuilder } from './LeafFilterBuilder';
import { RegexEvaluator } from './RegexFilterBuilder';

export class NotRegexFilterBuilder extends LeafFilterBuilder {
  caseInsensitive: boolean;

  constructor(caseInsensitive = false) {
    super();
    this.caseInsensitive = caseInsensitive;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const regexPattern = this.toComparableValues(field, values)[0];
    return new NotRegexEvaluator(field, regexPattern, this.caseInsensitive);
  }
}

export class NotRegexEvaluator<RecordType> extends RegexEvaluator<RecordType> {
  public evaluate(record: RecordType): boolean {
    return !super.evaluate(record);
  }
}
