import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Value } from '../api/Value';
import type { Filter } from '../api/Filter';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class LessThanFilterBuilder extends LeafFilterBuilder {
  equals: boolean;

  constructor(equals = false) {
    super();
    this.equals = equals;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const comparableValues = this.toComparableValues(field, values);
    return new LessThanEvaluator(field, comparableValues[0], this.equals);
  }
}

export class LessThanEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private value: Value;
  private equals: boolean;

  constructor(field: Field<RecordType>, value: Value, equals: boolean) {
    this.field = field;
    this.value = value;
    this.equals = equals;
  }

  public evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (!iterable) {
      return false;
    }
    for (const v of iterable) {
      if (this.evaluateValue(v)) {
        return true;
      }
    }
    return false;
  }

  private evaluateValue(v: Value): boolean {
    return v.lessThan(this.value, this.equals);
  }
}
