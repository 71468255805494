import { Filter } from '../api/Filter';
import type { FilterBuilder } from '../api/FilterBuilder';
import type { FilterBuilderFactory } from '../api/FilterBuilderFactory';
import type { FilterContext } from '../api/FilterContext';
import type { FilterEvaluator } from '../api/FilterEvaluator';

export abstract class CompoundOpFilterBuilder implements FilterBuilder {
  private filterBuilderFactory: FilterBuilderFactory;

  constructor(filterBuilderFactory: FilterBuilderFactory) {
    this.filterBuilderFactory = filterBuilderFactory;
  }

  build<RecordType>(
    filterContext: FilterContext<RecordType>,
    filter: Filter,
  ): FilterEvaluator<RecordType> {
    const evaluatorList: FilterEvaluator<RecordType>[] = [];
    const values = filter.getValues();
    if (values !== null) {
      for (const child of values) {
        if (child instanceof Filter) {
          const op = child.getOp();
          if (op === null) {
            throw new Error('Invalid filter: no operator');
          }
          const filterBuilder = this.filterBuilderFactory.getFilterBuilder(op);
          evaluatorList.push(filterBuilder.build(filterContext, child));
        } else {
          throw new Error('Invalid filter: child is not a filter');
        }
      }
    }
    return this._build(filterContext, evaluatorList);
  }

  protected abstract _build<RecordType>(
    filterContext: FilterContext<RecordType>,
    evaluators: FilterEvaluator<RecordType>[],
  ): FilterEvaluator<RecordType>;
}
