import { isArray, isObject, toString } from 'lodash';
import { isDynamicValue } from '../utils/dynamicBindings';
import { safeJsonParse } from '../../utils/json';
import { ComparableValue } from './api/ComparableValue';
import type { DynamicFieldFilterContext } from './api/DynamicFieldFilterContext';
import type { ExecutionInstance } from './api/ExecutionInstance';
import type { Field } from './api/Field';
import type { Filter } from './api/Filter';
import { FilterBuilderFactory } from './api/FilterBuilderFactory';
import type { FilterEvaluator } from './api/FilterEvaluator';
import type { Value } from './api/Value';
import { DataType } from './api/Op';

interface ConditionEvaluator {
  evaluate: (filter: Filter, instance: ExecutionInstance) => boolean;
}

export class ConditionEvaluatorImpl implements ConditionEvaluator {
  evaluate(filter: Filter | null, instance: ExecutionInstance): boolean {
    if (filter === null) {
      return false;
    }

    class ImplementedDynamicFilterContext implements DynamicFieldFilterContext<object> {
      createField(_filter: Filter): Field<object> {
        return new ConditionEvaluatorImpl().createFieldWithFilter(_filter, instance);
      }
    }

    const filterEvaluator: FilterEvaluator<object> = FilterBuilderFactory.INSTANCE.build<object>(
      new ImplementedDynamicFilterContext(),
      filter,
    );

    return filterEvaluator.evaluate(instance.context);
  }

  createFieldWithFilter(filter: Filter, instance: ExecutionInstance): Field<object> {
    const fieldOrTemplate = filter.getField();
    const expectedDataType = filter.getOp()?.getExpectedDataType();

    if (expectedDataType && expectedDataType !== DataType.ANY) {
      return this.createFieldWithDataType(fieldOrTemplate, expectedDataType, instance);
    }

    const dataType = this.identifyDataTypeWithFilter(filter, instance);
    return this.createFieldWithDataType(fieldOrTemplate, dataType, instance);
  }

  private createFieldWithDataType(
    field: string,
    dataType: DataType,
    instance: ExecutionInstance,
  ): Field<object> {
    return {
      getValues: () => {
        const value = this.resolveValue(field, instance);
        // used in missing and exists
        if (value === null || value === undefined) {
          return null;
        }
        return [new ComparableValue(this.toComparableValue(value, dataType), dataType)];
      },
      toValues: (values?: object[]) => {
        if (!values || values.length === 0) {
          return [];
        }
        const resolvedValues: Value[] = [];
        for (const value of values) {
          if (typeof value === 'string' && isDynamicValue(value)) {
            const resolvedValue = this.resolveValue(value, instance);
            resolvedValues.push(
              new ComparableValue(this.toComparableValue(resolvedValue, dataType), dataType),
            );
          } else {
            resolvedValues.push(
              new ComparableValue(this.toComparableValue(value, dataType), dataType),
            );
          }
        }
        return resolvedValues;
      },
    };
  }

  private resolveValue(fieldOrTemplate: string, instance: ExecutionInstance) {
    return instance.resolve(fieldOrTemplate);
  }

  private toComparableValue(
    value: string | number | boolean | object | null | undefined,
    dataType: DataType,
  ) {
    if (value === null) {
      return null;
    }
    switch (dataType) {
      case DataType.ANY:
      case DataType.OBJECT:
        return value;
      case DataType.NUMBER:
        return typeof value === 'number' ? value : Number(value);
      case DataType.STRING:
        return toString(value);
      case DataType.BOOLEAN:
        // eslint-disable-next-line no-nested-ternary -- remove
        return typeof value === 'boolean'
          ? value
          : typeof value === 'string'
            ? safeJsonParse(`${value}`, value as unknown as Record<string, unknown>)
            : value;
    }
  }

  private identifyDataTypeWithFilter(filter: Filter, instance: ExecutionInstance): DataType {
    const objectsToCheck = this.collectAllOperands(filter);
    const dataType: DataType | null = this.identityDataTypeFromTemplatedStrings(
      objectsToCheck,
      instance,
    );
    return dataType === null ? DataType.STRING : dataType;
  }

  private identityDataTypeFromTemplatedStrings(
    objectsToCheck: unknown[],
    instance: ExecutionInstance,
  ): DataType | null {
    for (const object of objectsToCheck) {
      if (typeof object === 'string') {
        if (isDynamicValue(object)) {
          const resolvedValue = instance.resolve(object);
          if (resolvedValue !== undefined) {
            return this.identifyDataType(resolvedValue);
          }
        } else {
          return this.identifyDataType(object);
        }
      }
    }
    return null;
  }

  private collectAllOperands(filter: Filter): unknown[] {
    const objectsToCheck: unknown[] = [];
    if (filter.getField()) {
      objectsToCheck.push(filter.getField());
    }
    const values = filter.getValues();
    const nullsafeList = values?.filter((value) => value !== null) ?? [];
    objectsToCheck.push(...nullsafeList);
    return objectsToCheck;
  }

  private identifyDataType(resolvedValue: unknown): DataType | null {
    let value = resolvedValue;
    if (typeof resolvedValue === 'string') {
      value = safeJsonParse(resolvedValue, resolvedValue as unknown as Record<string, unknown>);
    }

    if (value === null) {
      return null;
    } else if (typeof value === 'number') {
      return DataType.NUMBER;
    } else if (typeof value === 'boolean') {
      return DataType.BOOLEAN;
    } else if (isObject(value)) {
      return DataType.OBJECT;
    } else if (isArray(value)) {
      return value.length === 0 ? null : this.identifyDataType(value[0]);
    }
    return DataType.STRING;
  }
}
