import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchAllEvaluator } from '../api/MatchAllEvaluator';
import { LeafFilterBuilder } from './LeafFilterBuilder';
import { InFilterEvaluator } from './InFilterBuilder';

export class NotInFilterBuilder extends LeafFilterBuilder {
  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchAllEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const comparableValues = this.toComparableValues(field, values);
    return new NotInFilterEvaluator(field, comparableValues);
  }
}

export class NotInFilterEvaluator<RecordType> extends InFilterEvaluator<RecordType> {
  evaluate(record: RecordType): boolean {
    return !super.evaluate(record);
  }
}
