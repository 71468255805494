import { NotInFilterBuilder } from '../Op/NotInFilterBuilder';
import { AndFilterBuilder } from '../Op/AndFilterBuilder';
import { OrFilterBuilder } from '../Op/OrFilterBuilder';
import { ContainsFilterBuilder } from '../Op/ContainsFilterBuilder';
import { NotContainsFilterBuilder } from '../Op/NotContainsFilterBuilder';
import { LessThanFilterBuilder } from '../Op/LessThanFilterBuilder';
import { GreaterThanFilterBuilder } from '../Op/GreaterThanFilterBuilder';
import { MissingFilterBuilder } from '../Op/MissingFilterBuilder';
import { ExistsFilterBuilder } from '../Op/ExistsFilterBuilder';
import { RegexFilterBuilder } from '../Op/RegexFilterBuilder';
import { NotRegexFilterBuilder } from '../Op/NotRegexFilterBuilder';
import { MinLenFilterBuilder } from '../Op/MinLenFilterBuilder';
import { MaxLengthFilterBuilder } from '../Op/MaxLengthFilterBuilder';
import { IsBeforeFilterBuilder } from '../Op/date/IsBeforeFilterBuilder';
import { IsAfterFilterBuilder } from '../Op/date/IsAfterFilterBuilder';
import { IsBeforeTimeFilterBuilder } from '../Op/time/IsBeforeTimeFilterBuilder';
import { IsAfterTimeFilterBuilder } from '../Op/time/IsAfterTimeFilterBuilder';
import { EqualsFilterBuilder } from '../Op/EqualsFilterBuilder';
import { NotEqualsFilterBuilder } from '../Op/NotEqualsFilterBuilder';
import { InFilterBuilder } from '../Op/InFilterBuilder';
import type { FilterBuilder } from './FilterBuilder';
import { type Filter } from './Filter';
import type { FilterEvaluator } from './FilterEvaluator';
import type { FilterContext } from './FilterContext';
import { Op } from './Op';

export class FilterBuilderFactory {
  static readonly INSTANCE = new FilterBuilderFactory();
  #builders = new Map<Op, FilterBuilder>();

  constructor() {
    this.#builders.set(Op.EQUAL, new EqualsFilterBuilder());
    this.#builders.set(Op.NOT_EQUAL, new NotEqualsFilterBuilder());
    this.#builders.set(Op.CONTAINS, new ContainsFilterBuilder());
    this.#builders.set(Op.NOT_CONTAINS, new NotContainsFilterBuilder());
    this.#builders.set(Op.MISSING, new MissingFilterBuilder());
    this.#builders.set(Op.EXISTS, new ExistsFilterBuilder());
    this.#builders.set(Op.IN, new InFilterBuilder());
    this.#builders.set(Op.NOT_IN, new NotInFilterBuilder());
    this.#builders.set(Op.AND, new AndFilterBuilder(this));
    this.#builders.set(Op.OR, new OrFilterBuilder(this));
    this.#builders.set(Op.LT, new LessThanFilterBuilder());
    this.#builders.set(Op.LTE, new LessThanFilterBuilder(true));
    this.#builders.set(Op.REGEX, new RegexFilterBuilder());
    this.#builders.set(Op.NOT_REGEX, new NotRegexFilterBuilder());
    this.#builders.set(Op.IREGEX, new RegexFilterBuilder(true));
    this.#builders.set(Op.NOT_IREGEX, new NotRegexFilterBuilder(true));
    this.#builders.set(Op.MIN_LENGTH, new MinLenFilterBuilder());
    this.#builders.set(Op.MAX_LENGTH, new MaxLengthFilterBuilder());
    this.#builders.set(Op.GT, new GreaterThanFilterBuilder());
    this.#builders.set(Op.GTE, new GreaterThanFilterBuilder(true));

    // date type
    this.#builders.set(Op.DATE_IS_BEFORE, new IsBeforeFilterBuilder());
    this.#builders.set(Op.DATE_IS_ON_OR_BEFORE, new IsBeforeFilterBuilder(true));
    this.#builders.set(Op.DATE_IS_AFTER, new IsAfterFilterBuilder());
    this.#builders.set(Op.DATE_IS_ON_OR_AFTER, new IsAfterFilterBuilder(true));

    // time type
    this.#builders.set(Op.TIME_IS_BEFORE, new IsBeforeTimeFilterBuilder());
    this.#builders.set(Op.TIME_IS_EQUAL_TO_OR_BEFORE, new IsBeforeTimeFilterBuilder(true));
    this.#builders.set(Op.TIME_IS_AFTER, new IsAfterTimeFilterBuilder());
    this.#builders.set(Op.TIME_IS_EQUAL_TO_OR_AFTER, new IsAfterTimeFilterBuilder(true));
    this.#builders.set(Op.TIME_IS_EQUAL_TO, new EqualsFilterBuilder());
  }

  getFilterBuilder(op: Op): FilterBuilder {
    const operatorBuilder = this.#builders.get(op);
    if (operatorBuilder === undefined) {
      throw new Error(`No filter builder found for op: ${op.toString()}`);
    }
    return operatorBuilder;
  }

  build<RecordType>(
    filterContext: FilterContext<RecordType>,
    filter: Filter,
  ): FilterEvaluator<RecordType> {
    const op = filter.getOp();
    if (!op) {
      throw new Error('Filter op is required');
    }
    const filterBuilder = this.getFilterBuilder(op);
    return filterBuilder.build(filterContext, filter);
  }
}
