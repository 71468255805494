import { get, isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Value } from '../api/Value';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class ContainsFilterBuilder extends LeafFilterBuilder {
  caseInsensitive: boolean;

  constructor(caseInsensitive = false) {
    super();
    this.caseInsensitive = caseInsensitive;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const caseInsensitiveFromOption = get(filter.getOptions(), 'case_insensitive');
    const caseInsensitive = Boolean(caseInsensitiveFromOption || this.caseInsensitive);
    const comparableValues = this.toComparableValues(field, values);
    return new ContainsEvaluator(field, comparableValues[0], caseInsensitive);
  }
}

export class ContainsEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private value: Value;
  private caseInsensitive: boolean;

  constructor(field: Field<RecordType>, value: Value, caseInsensitive: boolean) {
    this.field = field;
    this.value = value;
    this.caseInsensitive = caseInsensitive;
  }

  public evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (!iterable) {
      return false;
    }
    for (const v of iterable) {
      if (this.evaluateValue(v)) {
        return true;
      }
    }
    return false;
  }

  private evaluateValue(v: Value): boolean {
    let s = v.toString();
    let substring = this.value.toString();
    if (this.caseInsensitive) {
      s = s.toLowerCase();
      substring = substring.toLowerCase();
    }
    return s.includes(substring);
  }
}
