import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class ExistsFilterBuilder extends LeafFilterBuilder {
  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
  ): FilterEvaluator<RecordType> {
    return new ExistsFilterEvaluator(field);
  }
}

class ExistsFilterEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;

  constructor(field: Field<RecordType>) {
    this.field = field;
  }

  evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    return iterable !== null;
  }
}
