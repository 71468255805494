import { isEmpty } from 'lodash';
import { MatchNoneEvaluator } from '../../api/MatchNoneEvaluator';
import type { Field } from '../../api/Field';
import type { Filter } from '../../api/Filter';
import type { FilterEvaluator } from '../../api/FilterEvaluator';
import type { Value } from '../../api/Value';
import { LeafFilterBuilder } from '../LeafFilterBuilder';

export class IsBeforeFilterBuilder extends LeafFilterBuilder {
  on: boolean;

  constructor(on = false) {
    super();
    this.on = on;
  }

  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const comparableValues = this.toComparableValues(field, values);
    return new IsBeforeEvaluator(field, comparableValues[0], this.on);
  }
}

export class IsBeforeEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private value: Value;
  private on: boolean;

  constructor(field: Field<RecordType>, value: Value, on: boolean) {
    this.field = field;
    this.value = value;
    this.on = on;
  }

  public evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (!iterable) {
      return false;
    }
    for (const v of iterable) {
      if (this.evaluateValue(v)) {
        return true;
      }
    }
    return false;
  }

  private evaluateValue(v: Value): boolean {
    return v.isBefore(this.value, this.on);
  }
}
