import { isEmpty } from 'lodash';
import type { Field } from '../api/Field';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import { MatchNoneEvaluator } from '../api/MatchNoneEvaluator';
import type { Filter } from '../api/Filter';
import type { Value } from '../api/Value';
import { LeafFilterBuilder } from './LeafFilterBuilder';

export class MinLenFilterBuilder extends LeafFilterBuilder {
  protected _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[],
  ): FilterEvaluator<RecordType> {
    if (isEmpty(values)) {
      return MatchNoneEvaluator.INSTANCE as FilterEvaluator<RecordType>;
    }
    const comparableValues = this.toComparableValues(field, values);
    return new MinLenEvaluator(field, comparableValues[0]);
  }
}

export class MinLenEvaluator<RecordType> implements FilterEvaluator<RecordType> {
  private field: Field<RecordType>;
  private minLength: Value;

  constructor(field: Field<RecordType>, minLength: Value) {
    this.field = field;
    this.minLength = minLength;
  }

  public evaluate(record: RecordType): boolean {
    const iterable = this.field.getValues(record);
    if (!iterable) {
      return false;
    }
    for (const v of iterable) {
      if (this.evaluateValue(v)) {
        return true;
      }
    }
    return false;
  }

  private evaluateValue(v: Value): boolean {
    return v.minLength(this.minLength);
  }
}
